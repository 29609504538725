import { Box, Button, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { OfficeMap, MapConfig } from '../../common/OfficeMap/OfficeMap';
import { useTelegram } from '../../../hooks/useTelegram';
import { DeskManagementDrawer } from './DeskManagementDrawer';
import { officeSpaceApi } from '../../../api/officeSpaceApi';
import { FullPageLoader } from '../../common/FullPageLoader/FullPageLoader';
import { OfficeSpaceListResponse } from '../../../api/types';

export function DeskManagementLayout() {
    const [selectedSpace, setSelectedSpace] = useState<string>('');
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const [mapConfig, setMapConfig] = useState<MapConfig>([]);

    const { data, isLoading } = officeSpaceApi.useListQuery({});

    const { colorScheme } = useTelegram();

    const handleOfficeSpaceClick = (value: string) => {
        setSelectedSpace(value);
    };

    const handleCloseDrawer = () => {
        setIsDrawerOpen(false);
    };

    const handleOpenDrawer = () => {
        setIsDrawerOpen(true);
    };

    useEffect(() => {
        if (data) {
            const mappedResponse: MapConfig = mapResponseToMapConfig(data.data);
            setMapConfig(mappedResponse);
        }
    }, [data]);

    if (isLoading) {
        return <FullPageLoader />;
    }

    return (
        <Box p={3} bgcolor={colorScheme === 'dark' ? '#1F2023' : '#FFFFFF'} height="100vh">
            <Stack direction="column" justifyContent="space-between" height="100%">
                <Stack direction="column" gap={3} flex={1}>
                    <Box
                        p={1}
                        color={colorScheme === 'dark' ? '#FFF' : '#000'}
                        bgcolor={colorScheme === 'dark' ? '#27292D' : '#E8E8E8'}
                    >
                        <Typography textAlign="center">Update Desk Settings</Typography>
                    </Box>
                    <OfficeMap
                        key={JSON.stringify(mapConfig)}
                        onOfficeSpaceClick={handleOfficeSpaceClick}
                        selectedSpace={selectedSpace}
                        config={mapConfig}
                    />
                </Stack>
                {!!selectedSpace && (
                    <Button onClick={handleOpenDrawer} variant="contained">
                        Update Desk {selectedSpace}
                    </Button>
                )}
            </Stack>
            <DeskManagementDrawer
                open={isDrawerOpen}
                onClose={handleCloseDrawer}
                value={selectedSpace}
            />
        </Box>
    );
}

const mapResponseToMapConfig = (data: OfficeSpaceListResponse['data']) => {
    return data.map((item) => ({
        id: item.name,
        options: {},
    }));
};
