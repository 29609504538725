import { Autocomplete, TextField } from '@mui/material';
import React from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

type ControlledAutocompleteProps<T extends FieldValues> = {
    control: Control<T>;
    name: Path<T>;
    options: { label: string; value: string }[];
    disabled?: boolean;
    label?: string;
    onInputChange: (value: string) => void;
    inputValue?: string;
};

export function ControlledAutocomplete<T extends FieldValues>({
    control,
    name,
    options,
    disabled,
    label,
    onInputChange,
    inputValue,
}: ControlledAutocompleteProps<T>) {
    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => (
                <Autocomplete
                    disabled={disabled}
                    inputValue={inputValue}
                    onInputChange={(event) => {
                        const typedEvent = event as React.ChangeEvent<HTMLInputElement>;
                        if (typedEvent?.target && typeof typedEvent.target.value === 'string') {
                            onInputChange(typedEvent.target.value);
                        }
                    }}
                    // This prop disables the original behaviour of the component
                    // and allows us to fetch data from the server
                    filterOptions={(x) => x}
                    options={options}
                    {...field}
                    // Override value to display a correct option.label
                    // if not specified like this, then actual value is displayed (id for example)
                    value={options?.filter((item) => item?.value === field?.value)[0] || null}
                    onChange={(_e, option) => {
                        if (option) {
                            field.onChange(option.value);
                        }

                        if (option === null) {
                            field.onChange(null);
                        }
                    }}
                    renderInput={(props) => <TextField {...props} label={label} />}
                />
            )}
        />
    );
}
