import { TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

type ControlledDatePickerProps<T extends FieldValues> = {
    control: Control<T>;
    name: Path<T>;
    label?: string;
    disabled?: boolean;
    minDate?: string | null;
    maxDate?: string | null;
};

export function ControlledDatePicker<T extends FieldValues>({
    control,
    name,
    disabled,
    label,
    minDate,
    maxDate,
}: ControlledDatePickerProps<T>) {
    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        {...field}
                        minDate={minDate}
                        maxDate={maxDate}
                        label={label}
                        inputFormat="DD-MM-YYYY"
                        disabled={disabled}
                        value={field.value}
                        onChange={(value) => {
                            if (dayjs(value).isValid()) {
                                field.onChange(dayjs(value).format('YYYY-MM-DDTHH:mm:ss[Z]'));
                            } else {
                                field.onChange(value);
                            }
                        }}
                        renderInput={(props) => <TextField {...props} />}
                    />
                </LocalizationProvider>
            )}
        />
    );
}
