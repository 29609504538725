import { useForm, SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import { Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DeskManagementFormSchema, deskManagementFormSchema } from './deskManagementForm.schema';
import { USER_TEAM } from '../../../../../constants';
import { ControlledCheckboxGroup } from '../../../../common/ControlledCheckboxGroup/ControlledCheckboxGroup';
import { ControlledSwitch } from '../../../../common/ControlledSwitch/ControlledSwitch';
import { OfficeSpaceListResponse } from '../../../../../api/types';
import { officeSpaceApi } from '../../../../../api/officeSpaceApi';
import { useSnackbarContext } from '../../../../common/SnackbarProvider/SnackbarProvider';

type DeskManagementFormProps = {
    data: {
        id: string;
        name: string;
        bookingTeams: USER_TEAM[] | null;
        users?: OfficeSpaceListResponse['data'][number]['users'];
    };
};

export function DeskManagementForm({ data }: DeskManagementFormProps) {
    const {
        formState: { dirtyFields },
        handleSubmit,
        control,
        watch,
        setValue,
        reset,
    } = useForm<DeskManagementFormSchema>({
        resolver: zodResolver(deskManagementFormSchema),
        defaultValues: {
            bookingTeams: data.bookingTeams,
        },
    });

    const [updateOfficeSpace, { isLoading }] = officeSpaceApi.useUpdateOfficeSpaceMutation();

    const { showSnackbar } = useSnackbarContext();

    const onSubmit: SubmitHandler<DeskManagementFormSchema> = async (formData) => {
        await updateOfficeSpace({ id: data.id, bookingTeams: formData.bookingTeams })
            .unwrap()
            .then(() => {
                showSnackbar('Office space successfully updated', 'success');
            })
            .catch((err: { status: number }) => {
                if (err.status === 409) {
                    showSnackbar('The desk has active bookings. Please remove them first', 'error');
                } else {
                    showSnackbar('Error occured while updating the office space', 'error');
                }
            });
    };

    const sendData = handleSubmit(onSubmit);

    useEffect(() => {
        reset({
            bookingTeams: data?.bookingTeams,
        });
    }, [data]);

    const isBooked = isDeskBooked(data);

    return (
        <form onSubmit={sendData} style={{ flex: '1' }}>
            <Stack direction="column" height="100%">
                <Stack flex={1}>
                    <ControlledSwitch
                        name="outOfService"
                        control={control}
                        disabled
                        label="Out Of Service"
                    />
                    <Typography>Available for:</Typography>
                    <ControlledCheckboxGroup
                        options={checkboxOptions}
                        name="bookingTeams"
                        control={control}
                        setValue={setValue}
                        watch={watch}
                        disabled={isBooked}
                    />
                    {isBooked && (
                        <Typography>
                            The desk has active bookings. Please remove all bookings first.
                        </Typography>
                    )}
                </Stack>
                <Stack>
                    <LoadingButton
                        loading={isLoading}
                        disabled={!Object.keys(dirtyFields).length}
                        type="submit"
                        variant="contained"
                    >
                        Update desk {data.name}
                    </LoadingButton>
                </Stack>
            </Stack>
        </form>
    );
}

const checkboxOptions = [
    { value: USER_TEAM.TECHNICAL, label: USER_TEAM.TECHNICAL },
    { value: USER_TEAM.ADMINISTRATIVE, label: USER_TEAM.ADMINISTRATIVE },
];

const isDeskBooked = (data: DeskManagementFormProps['data']): boolean => {
    const hasBookings = !!data.users?.length;

    if (!hasBookings) return false;

    const isBookedNow = !!data.users?.some((user) => {
        const now = new Date().toISOString();
        const isPermanentBooking = user.booking?.startDate && !user.booking.endDate;

        if (isPermanentBooking) return true;

        return (
            (dayjs(now).isAfter(user.booking?.startDate, 'day') &&
                dayjs(now).isBefore(user.booking?.endDate, 'day')) ||
            dayjs(now).isSame(user.booking?.startDate, 'day') ||
            dayjs(now).isSame(user.booking?.endDate, 'day')
        );
    });

    return isBookedNow;
};
