import React, { useEffect, useState } from 'react';
import { Button, Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import dayjs from 'dayjs';
import { BookingsList } from '../../common/BookingsList/BookingsList';
import { useTelegram } from '../../../hooks/useTelegram';
import { officeSpaceBookingApi } from '../../../api/officeSpaceBookingApi';
import { FullPageLoader } from '../../common/FullPageLoader/FullPageLoader';
import { DeskBookingsFiltersDrawer } from './DeskBookingsFiltersDrawer/DeskBookingsFiltersDrawer';
import { ManageDeskBookingsFiltersSchema } from './DeskBookingsFiltersDrawer/manageDeskBookingsFilters.schema';
import { usersApi } from '../../../api/usersApi';
import { officeSpaceApi } from '../../../api/officeSpaceApi';
import { mapBookingsToListData, mapOfficeSpacesToSelectData, mapUsersToSelectData } from './utils';
import { BookingsListItems } from './types';
import { AddNewBookingsDrawer } from './AddNewBookingDrawer/AddNewBookingDrawer';
import { useSnackbarContext } from '../../common/SnackbarProvider/SnackbarProvider';
import { UpdateBookingDrawer } from './UpdateBookingDrawer/UpdateBookingDrawer';
import { defaultFilters } from './constants';
import { SORT_OPTION } from '../../../types';
import { USER_STATUS } from '../../../constants';

export const ManageDeskBookingsLayout: React.FC = () => {
    const { colorScheme } = useTelegram();
    const { showSnackbar } = useSnackbarContext();

    const [getUsers, { isLoading: areUsersLoading }] = usersApi.useLazyListQuery();
    const [getOfficeSpaces, { isLoading: areOfficeSpacesLoading }] =
        officeSpaceApi.useLazyListQuery();
    const [deleteBookings] = officeSpaceBookingApi.useDeleteMutation();

    const [bookings, setBookings] = useState<BookingsListItems>([]);
    const [users, setUsers] = useState<{ label: string; value: string }[]>([]);
    const [officeSpaces, setOfficeSpaces] = useState<{ label: string; value: string }[]>([]);

    const [isFiltersDrawerOpen, setIsFiltersDrawerOpen] = useState<boolean>(false);
    const [isNewBookingDrawerOpen, setIsNewBookingDrawerOpen] = useState<boolean>(false);
    const [isUpdateBookingDrawerOpen, setIsUpdateBookingDrawerOpen] = useState<boolean>(false);

    const [bookingToUpdateId, setBookingToUpdateId] = useState<string>('');

    const [isSelectAllChecked, setIsSelectAllChecked] = useState<boolean>(false);

    const [filters, setFilters] = useState<ManageDeskBookingsFiltersSchema>(defaultFilters);

    const { isLoading: areBookingsLoading, data: bookingsData } =
        officeSpaceBookingApi.useListQuery({
            limit: 100,
            filters: {
                userId: filters.userId ? [filters.userId] : null,
                spaceNumber: filters.deskNumber ? [filters.deskNumber] : null,
                startDate: filters.startDate ? filters.startDate : dayjs().format('YYYY-MM-DD'),
                endDate: filters.endDate,
            },
            sort: [{ field: 'startDate', order: SORT_OPTION.ASC }],
        });

    const handleFiltersChange = (value: ManageDeskBookingsFiltersSchema) => {
        setFilters(value);
    };

    const handleOpenFiltersDrawer = () => {
        setIsFiltersDrawerOpen(true);
    };

    const handleCloseFiltersDrawer = () => {
        setIsFiltersDrawerOpen(false);
    };

    const handleOpenNewBookingDrawer = () => {
        setIsNewBookingDrawerOpen(true);
    };

    const handleCloseNewBookingDrawer = () => {
        setIsNewBookingDrawerOpen(false);
    };

    const handleOpenUpdateBookingDrawer = (bookingId: string) => {
        setIsUpdateBookingDrawerOpen(true);
        setBookingToUpdateId(bookingId);
    };

    const handleCloseUpdateBookingDrawer = () => {
        setIsUpdateBookingDrawerOpen(false);
        setBookingToUpdateId('');
    };

    const handleCheckboxChange = (
        _event: React.ChangeEvent<HTMLInputElement>,
        checked: boolean,
    ) => {
        setIsSelectAllChecked(checked);
    };

    const handleDeleteClick = async (bookingId: string) => {
        await deleteBookings({ ids: [bookingId] })
            .unwrap()
            .then(() => {
                showSnackbar('Booking was successfully deleted', 'success');
            })
            .catch(() => {
                showSnackbar('Error while deteting a booking', 'error');
            });
    };

    const handleDeleteAllClick = async () => {
        const ids = bookings.map((booking) => booking.bookingId);
        await deleteBookings({ ids });
    };

    useEffect(() => {
        if (bookingsData) {
            const mappedResponse = mapBookingsToListData(bookingsData.data);
            setBookings(mappedResponse);
        }
    }, [bookingsData]);

    useEffect(() => {
        const fetchUsers = async () => {
            const { data: response } = await getUsers({
                filters: { status: [USER_STATUS.ACTIVE] },
            });

            if (response?.data) {
                const mappedResponse = mapUsersToSelectData(response.data);
                setUsers(mappedResponse);
                return;
            }

            showSnackbar('Error while getting users', 'error');
        };

        fetchUsers();
    }, []);

    useEffect(() => {
        const fetchOfficeSpaces = async () => {
            const { data: response } = await getOfficeSpaces({});

            if (response?.data) {
                const mappedResponse = mapOfficeSpacesToSelectData(response.data);
                setOfficeSpaces(mappedResponse);
                return;
            }

            showSnackbar('Error while getting office spaces', 'error');
        };

        fetchOfficeSpaces();
    }, []);

    if (areBookingsLoading || areOfficeSpacesLoading || areUsersLoading) {
        return <FullPageLoader />;
    }

    return (
        <>
            <Stack bgcolor={colorScheme === 'dark' ? '#1F2023' : '#FFFFFF'} height="100vh">
                <Stack
                    px={3}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    py={1}
                    boxShadow="0 3px 10px rgb(0 0 0 / 0.2)"
                    zIndex={2}
                >
                    <Stack direction="row" alignItems="center" gap={3}>
                        <Typography variant="h6" color={colorScheme === 'dark' ? '#FFF' : '#000'}>
                            Desk Bookings
                        </Typography>
                        <FormControlLabel
                            label="All Bookings"
                            sx={{
                                color: colorScheme === 'dark' ? '#FFF' : '#000',
                            }}
                            control={<Checkbox onChange={handleCheckboxChange} />}
                        />
                    </Stack>
                    <TuneIcon
                        color={filters !== defaultFilters ? 'primary' : 'action'}
                        onClick={handleOpenFiltersDrawer}
                    />
                </Stack>
                <Stack px={3} flexGrow={1} overflow="auto" mt={3}>
                    <BookingsList
                        items={bookings}
                        onEditClick={handleOpenUpdateBookingDrawer}
                        onDeleteClick={handleDeleteClick}
                    />
                </Stack>
                <Stack px={3} py={2} boxShadow="0 3px 10px rgb(0 0 0 / 0.2)" zIndex={2}>
                    <Button
                        variant="contained"
                        onClick={handleOpenNewBookingDrawer}
                        sx={{ display: isSelectAllChecked ? 'none' : 'flex' }}
                    >
                        <Stack direction="row" gap={1}>
                            <AddCircleOutlinedIcon />
                            <span>Add New Booking</span>
                        </Stack>
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={handleDeleteAllClick}
                        sx={{ display: isSelectAllChecked ? 'flex' : 'none' }}
                    >
                        Delete All Bookings
                    </Button>
                </Stack>
            </Stack>

            <DeskBookingsFiltersDrawer
                open={isFiltersDrawerOpen}
                onClose={handleCloseFiltersDrawer}
                filters={filters}
                onFiltersChange={handleFiltersChange}
                officeSpacesSelectOptions={officeSpaces}
            />
            <AddNewBookingsDrawer
                open={isNewBookingDrawerOpen}
                onClose={handleCloseNewBookingDrawer}
                usersSelectOptions={users}
            />
            <UpdateBookingDrawer
                open={isUpdateBookingDrawerOpen}
                bookingId={bookingToUpdateId}
                onClose={handleCloseUpdateBookingDrawer}
            />
        </>
    );
};
