import { Dayjs } from 'dayjs';
import { REPEAT_VALUE, USER_STATUS, USER_TEAM } from '../constants';
import { MyBooking } from '../store/reducers/allBookings';
import { ApplicationUser, MeetingRoomBooking, SORT_OPTION } from '../types';

export type ListResponse<T> = {
    data: T[];
    metadata: {
        count: number;
        offset: number;
        limit: number;
    };
};

export interface GetUserResponse {
    id: string;
    name: string;
    surname: string;
    telegramUsername: string;
    telegramUserId: number;
    isAdmin: boolean;
    team: USER_TEAM[] | null;
    status: USER_STATUS;
    createdAt: string;
    updatedAt: string;
}

export interface ChangeUserBody {
    id: string;
    name?: string;
    surname?: string;
    queryId?: string;
    status?: USER_STATUS;
    team?: USER_TEAM[] | null;
    isAdmin?: boolean;
}

export interface CreateBookingPeriodBody {
    queryId: string;
    startDate: Dayjs | null;
    endDate: Dayjs | null;
    chatId?: string;
    telegramUserId: number;
}

export interface GetMeetingRoomUsersResponse {
    id: string;
    name: string;
    surname: string;
    telegramUserName: string;
    telegramUserId: number;
    userMeetingRoomInfo: {
        id: string;
        spaceName: string;
        roomId: string;
        date: string;
        endTime: string;
        startTime: string;
        isRepeated: boolean;
        repeatValue: REPEAT_VALUE | null;
        repeatEndDate: string | null;
        isRepeatAlways: boolean;
    };
}

export interface BookMeetingRoomBody {
    meetingRoomId: string;
    telegramUserId: number;
    date: string;
    startTime: string;
    endTime: string;
    queryId: string;
    spaceName: string;
    allDay: boolean;
    isRepeated?: boolean;
    repeatValue?: REPEAT_VALUE | null;
    repeatEndDate?: string | null;
    isRepeatAlways?: boolean;
    /*   userEmail: string; */
}

export interface GetBookedOfficeSpacesResponse {
    id: string;
    name: string;
    startDate: string;
    endDate: string | null;
    userSpaceId: string;
    isRepeatAlways: boolean;
    bookingTeams: USER_TEAM[] | null;
    spaceUser: {
        id: string;
        name: string;
        surname: string;
        telegramUserName: string;
        telegramUserId: number;
    };
}

export interface BookOfficeSpaceBody {
    queryId: string;
    spaceName: string;
    userId: string;
    startDate: string;
    endDate?: string | null;
    isRepeatAlways?: boolean;
}

export interface RegisterUserBody {
    telegramUserName: string;
    telegramUserId: number;
    name: string;
    surname: string;
    queryId: string;
}

export interface EditOfficeSpaceBody {
    bookingId: string;
    spaceName: string;
    startDate?: string | null;
    endDate?: string | null;
}

export interface EditMeetingRoomBooking {
    id: string;
    startTime: string;
    endTime: string;
    allDay: boolean;
    isRepeated?: boolean;
    repeatValue?: REPEAT_VALUE | null;
    repeatEndDate?: string | null;
    isRepeatAlways?: boolean;
    isEditCurrent?: boolean;
    editDate?: string;
    /*   userEmail: string; */
}

export interface GetAllBookingsResponse {
    allBookings: MyBooking[];
    expiredAllBookings: MyBooking[];
    officeSpaceBookings: MyBooking[];
    expiredOfficeSpaceBookings: MyBooking[];
    meetingRoomBookings: MyBooking[];
    expiredMeetingRoomBookings: MyBooking[];
    allBookingsCount: number;
    officeSpaceBookingsCount: number;
    meetingRoomBookingsCount: number;
    expiredAllBookingsCount: number;
    expiredOfficeSpaceBookingsCount: number;
    expiredMeetingRoomBookingsCount: number;
    offset: number;
    historyOffset: number;
    limit: number;
}

export interface RemoveMeetingRoomBookingBody {
    id: string;
    removeAll?: boolean;
    removeCurrent?: boolean;
    removeUpcoming?: boolean;
    removeDate?: string;
}

export type GetUsersRequestBody = {
    search?: string;
    limit?: number;
    offset?: number;
    includeCount?: boolean;
    searchFields?: string[];
    filters?: {
        id?: string[];
        telegramUserId?: number[];
        status?: USER_STATUS[];
    };
};

export type GetUsersResponse = ListResponse<{
    id: string;
    name: string;
    surname: string;
    telegramUserName: string;
    telegramUserId: number;
    isAdmin: boolean;
    team: USER_TEAM[] | null;
    status: USER_STATUS;
}>;

export type UpdateDeskBody = {
    id: string;
    name?: string;
    bookingTeams?: USER_TEAM[] | null;
};

export type GetOfficeSpaceByIdResponse = {
    id: string;
    name: string;
    bookingTeams: USER_TEAM[] | null;
};

export type OfficeSpaceListResponse = ListResponse<{
    id: string;
    name: string;
    bookingTeams: USER_TEAM[] | null;
    users?: {
        id: string;
        name: string;
        surname: string;
        telegramUserName: string;
        telegramUserId: number;
        isAdmin: boolean;
        team: USER_TEAM[] | null;
        status: USER_STATUS;
        booking?: { startDate: string; endDate: string | null };
    }[];
}>;

export type OfficeSpaceListRequestBody = {
    limit?: number;
    offset?: number;
    include?: string[];
    filters?: {
        id?: string[];
        name?: string[];
    };
};

export type OfficeSpaceBookingListBody = {
    limit?: number;
    offset?: number;
    includeCount?: boolean;
    filters?: {
        id?: string[] | null;
        userId?: string[] | null;
        spaceNumber?: string[] | null;
        startDate?: string | null;
        endDate?: string | null;
    };
    sort?: { field: string; order: SORT_OPTION }[];
};

export type OfficeSpaceBookingListResponse = ListResponse<{
    bookingId: string;
    startDate: string;
    endDate: string | null;
    isRepeatAlways: boolean;
    user: {
        id: string;
        name: string;
        surname: string;
        telegramUserName: string;
        telegramUserId: number;
        createdAt: string;
        updatedAt: string;
    };
    space: {
        id: string;
        name: string;
        createdAt: string;
        updatedAt: string;
        bookingTeams: USER_TEAM[] | null;
    };
}>;

export type MeetingRoomBookingListResponse = ListResponse<
    MeetingRoomBooking & {
        user?: ApplicationUser;
        meetingRoom?: {
            id: string;
            name: string;
        };
    }
>;

export type MeetingRoomBookingsListBody = {
    limit?: number;
    offset?: number;
    includeCount?: boolean;
    include?: MEETING_ROOM_BOOKING_LIST_INCLUDE[];
    filters?: {
        id?: string[];
        userId?: string[];
        meetingRoomName?: string[];
        date?: string;
    };
};

export enum MEETING_ROOM_BOOKING_LIST_INCLUDE {
    USERS = 'users',
    MEETING_ROOMS = 'meeting_rooms',
}

export type CreateMeetingRoomBookingBody = {
    queryId: string;
    telegramUserId: number;
    meetingRoomName: string;
    date: string;
    startTime: string;
    endTime: string;
    allDay?: boolean;
    isRepeated?: boolean;
    repeatValue?: REPEAT_VALUE;
    repeatEndDate?: string;
    permanent?: boolean;
};
