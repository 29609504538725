export type MeetingRoomBooking = {
    id: string;
    spaceId: string;
    userId: string;
    date: string;
    startTime: string;
    endTime: string;
    isRepeated?: boolean;
    repeatValue?: string | null;
    repeatEndDate?: string | null;
    repeatStartDate?: string | null;
    isRepeatAlways?: boolean;
    isExpired?: boolean;
    repeatBookingId?: string | null;
    googleEventId?: string | null;
};

export type ApplicationUser = {
    id: string;
    name: string;
    surname: string;
    telegramUserName: string;
    telegramUserId: number;
    isAdmin: boolean;
    team: USER_TEAM[] | null;
    status: USER_STATUS;
};

export enum USER_STATUS {
    ACTIVE = 'active',
    PENDING = 'pending',
    ARCHIVED = 'archived',
}

export enum USER_TEAM {
    TECHNICAL = 'Technical',
    ADMINISTRATIVE = 'Administrative',
}

export enum SORT_OPTION {
    ASC = 'asc',
    DESC = 'desc',
}
